import { BehaviorSubject } from "rxjs";
import { Injectable } from "@angular/core";
import { Pages } from "../enums/page";
import { Router } from "@angular/router";
import { Step } from "../entities/step";
import { StepState } from "../enums/step-state";

@Injectable({
  providedIn: "root"
})
export class NavigationService {
  constructor(
    private router: Router
  ) {
  }

  readonly defaultPath = "/welcome";

  steps: Step[] = [
    {
      identifier: "welcome",
      title: "Welcome",
      lastStep: false,
      parentStepID: null,
      stepState: StepState.Disabled,
      page: Pages.Welcome,
      nextStepIdentifier: "privacy",
      parentOrder: 1,
      leftCompleted: false,
      rightCompleted: false
    },
    {
      identifier: "privacy",
      title: "Keeping your information safe",
      lastStep: false,
      parentStepID: Pages.Welcome,
      stepState: StepState.Disabled,
      page: Pages.KeepYourInformationSafe,
      previousStepIdentifier: "welcome",
      nextStepIdentifier: "successful-meeting",
      leftCompleted: false,
      rightCompleted: false
    },
    {
      identifier: "successful-meeting",
      title: "What would make this a successful meeting for you today?",
      lastStep: true,
      parentStepID: Pages.Welcome,
      stepState: StepState.Disabled,
      page: Pages.WhyYourHere,
      previousStepIdentifier: "privacy",
      nextStepIdentifier: "wellbeing-check-in",
      leftCompleted: false,
      rightCompleted: false
    },
    {
      identifier: "wellbeing-check-in",
      title: "Wellbeing check-in",
      lastStep: false,
      parentStepID: null,
      stepState: StepState.Disabled,
      page: Pages.WellbeingCheckIn,
      previousStepIdentifier: "successful-meeting",
      nextStepIdentifier: "financial-wellbeing",
      parentOrder: 2,
      leftCompleted: false,
      rightCompleted: false
    },
    {
      identifier: "financial-wellbeing",
      title: "Financial wellbeing",
      lastStep: false,
      parentStepID: Pages.WellbeingCheckIn,
      stepState: StepState.Disabled,
      page: Pages.FinancialWellbeingScore,
      previousStepIdentifier: "wellbeing-check-in",
      nextStepIdentifier: "physical-wellbeing",
      leftCompleted: false,
      rightCompleted: false
    },
    {
      identifier: "physical-wellbeing",
      title: "Physical wellbeing",
      lastStep: false,
      parentStepID: Pages.WellbeingCheckIn,
      stepState: StepState.Disabled,
      page: Pages.PhysicalWellbeingScore,
      previousStepIdentifier: "financial-wellbeing",
      nextStepIdentifier: "mental-wellbeing",
      leftCompleted: false,
      rightCompleted: false
    },
    {
      identifier: "mental-wellbeing",
      title: "Mental wellbeing",
      lastStep: true,
      parentStepID: Pages.WellbeingCheckIn,
      stepState: StepState.Disabled,
      page: Pages.MentalWellbeingScore,
      previousStepIdentifier: "physical-wellbeing",
      nextStepIdentifier: "about-you",
      leftCompleted: false,
      rightCompleted: false
    },
    {
      identifier: "about-you",
      title: "About you",
      lastStep: false,
      parentStepID: null,
      stepState: StepState.Disabled,
      page: Pages.AboutYou,
      previousStepIdentifier: "mental-wellbeing",
      nextStepIdentifier: "lifestyle",
      parentOrder: 3,
      leftCompleted: false,
      rightCompleted: false
    },
    {
      identifier: "lifestyle",
      title: "Lifestyle",
      lastStep: false,
      parentStepID: Pages.AboutYou,
      stepState: StepState.Disabled,
      page: Pages.Lifestyle,
      previousStepIdentifier: "about-you",
      nextStepIdentifier: "wealth",
      leftCompleted: false,
      rightCompleted: false
    },
    {
      identifier: "wealth",
      title: "Wealth",
      lastStep: false,
      parentStepID: Pages.AboutYou,
      stepState: StepState.Disabled,
      page: Pages.Wealth,
      previousStepIdentifier: "lifestyle",
      nextStepIdentifier: "future-protection",
      leftCompleted: false,
      rightCompleted: false
    },
    {
      identifier: "future-protection",
      title: "Future Protection",
      lastStep: false,
      parentStepID: Pages.AboutYou,
      stepState: StepState.Disabled,
      page: Pages.FutureProtection,
      previousStepIdentifier: "wealth",
      nextStepIdentifier: "cashflow",
      leftCompleted: false,
      rightCompleted: false
    },
    {
      identifier: "cashflow",
      title: "Cashflow",
      lastStep: true,
      parentStepID: Pages.AboutYou,
      stepState: StepState.Disabled,
      page: Pages.Cashflow,
      previousStepIdentifier: "future-protection",
      nextStepIdentifier: "your-objectives",
      leftCompleted: false,
      rightCompleted: false
    },
    {
      identifier: "your-objectives",
      title: "Your objectives",
      lastStep: false,
      parentStepID: null,
      stepState: StepState.Disabled,
      page: Pages.YourObjectives,
      previousStepIdentifier: "cashflow",
      nextStepIdentifier: "set-your-objectives",
      parentOrder: 4,
      leftCompleted: false,
      rightCompleted: false,
      overriddenStepNavigationIdentifier: "set-your-objectives"
    },
    {
      identifier: "set-your-objectives",
      title: "Set your objectives",
      lastStep: false,
      parentStepID: Pages.YourObjectives,
      stepState: StepState.Disabled,
      page: Pages.SetYourObjectives,
      previousStepIdentifier: "your-objectives",
      nextStepIdentifier: "order-objective-priority",
      leftCompleted: false,
      rightCompleted: false
    },
    {
      identifier: "order-objective-priority",
      title: "Let's order by priority",
      lastStep: false,
      parentStepID: Pages.YourObjectives,
      stepState: StepState.Disabled,
      page: Pages.OrderObjectivePriority,
      previousStepIdentifier: "set-your-objectives",
      nextStepIdentifier: "connect-your-objectives",
      leftCompleted: false,
      rightCompleted: false
    },
    {
      identifier: "connect-your-objectives",
      title: "Connecting your objectives with advice",
      lastStep: true,
      parentStepID: Pages.YourObjectives,
      stepState: StepState.Disabled,
      page: Pages.ConnectYourObjectives,
      previousStepIdentifier: "order-objective-priority",
      nextStepIdentifier: "advice-fee-overview",
      leftCompleted: false,
      rightCompleted: false
    },
    {
      identifier: "advice-fee-overview",
      title: "Agreement",
      titleForPdfReport: "Advice fee overview",
      lastStep: true,
      parentStepID: null,
      stepState: StepState.Disabled,
      page: Pages.AdviceFeeOverview,
      parentOrder: 5,
      leftCompleted: false,
      rightCompleted: false,
      previousStepIdentifier: "connect-your-objectives",
      nextStepIdentifier: "expect-next",
    },
    {
      identifier: "expect-next",
      title: "What to Expect Next",
      lastStep: true,
      parentStepID: Pages.AdviceFeeOverview,
      stepState: StepState.Disabled,
      page: Pages.ExpectNext,
      leftCompleted: false,
      rightCompleted: false,
      previousStepIdentifier: "advice-fee-overview",
    },
    {
      identifier: "your-health",
      title: "Your Health",
      lastStep: false,
      parentStepID: Pages.AdviceFeeOverview,
      stepState: StepState.Disabled,
      page: Pages.YourHealth,
      leftCompleted: false,
      rightCompleted: false,
      previousStepIdentifier: "expect-next",
    }
  ];

  currentStep = new BehaviorSubject(this.steps[0]);

  setActivePage(page: Pages): void {
    const step = this.steps.find(x => x.page === page);

    if (!step) return;

    step.stepState = StepState.Current;
    
    if (step.parentStepID) {
      const parentStep = this.steps.find(x => x.page === step.parentStepID);

      if (parentStep) parentStep.stepState = StepState.Current;
    }

    this.currentStep.next(step);
  }

  async navigateForward(): Promise<boolean> {
    return await this.navigate(this.currentStep.getValue().nextStepIdentifier);
  }

  async navigateBack(): Promise<boolean> {
    return await this.navigate(this.currentStep.getValue().previousStepIdentifier);
  }

  async navigate(route?: string): Promise<boolean> {
    if (route) {
      return await this.router.navigate([`/${route}`]);
    }

    return await this.router.navigate([this.defaultPath]);
  }
}
