export enum Pages {
    Welcome = 1,
    KeepYourInformationSafe = 2,
    WhyYourHere = 3,
    WellbeingCheckIn = 4,
    FinancialWellbeingScore = 5,
    PhysicalWellbeingScore = 6,
    MentalWellbeingScore = 7,
    AboutYou = 8,
    YourObjectives = 9,
    Agreement = 10,
    Lifestyle = 11,
    Wealth = 12,
    FutureProtection = 13,
    Cashflow = 14,
    SetYourObjectives = 15,
    OrderObjectivePriority = 16,
    ConnectYourObjectives = 17,
    AdviceFeeOverview = 18,
    ExpectNext = 19,
    YourHealth = 20
}
